<template>
<v-container fluid>
  <h2 class="d-flex justify-space-between">Artwork Sales Performance (since 2022)</h2>
  <div class="d-flex">
  <v-spacer />
  <v-btn
      class="ma-2"
      color="primary"
      @click="downloadExcel"
    >
      <v-icon>mdi-table</v-icon>
    </v-btn>
  </div>
  <select-artist :useActiveArtist="false" @onChange="handleArtistChange"></select-artist>
  <v-data-table v-if="artworks.length"
    :headers="headers"
    :items="artworks"
    :disable-pagination="true"
    :hide-default-footer=true
    :loading="loading"
    loading-text="Crunching data ... Please wait"
    class="elevation-1"
  >
    <template v-slot:[`item.image`]="{ item }">
      <v-img class="my-2" max-height="150px" max-width="150px"
          :src="`${imageUrl}/300px/${item.image}`"
        ></v-img>
    </template>
    <template v-slot:[`item.value`]="{ item }">
      <span>£{{item.value.toFixed(2)}}</span>
    </template>
  </v-data-table>
</v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ReportApi from '@/api/admin/report'
import xlsx from 'xlsx'
import SelectArtist from '@/components/SelectArtist'

export default {
  components: {
    SelectArtist
  },
  data () {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      artworks: [],
      artistCode: null,
      loading: false,
      // filteredArtworks: [],
      headers: [
        {
          text: 'Image',
          sortable: false,
          value: 'image'
        },
        {
          text: 'Artwork Code',
          sortable: true,
          value: 'artworkCode'
        },
        {
          text: 'Title',
          value: 'title'
        },
        {
          text: 'Total Revenue',
          align: 'end',
          value: 'value'
        },
        {
          text: 'Total',
          width: '8%',
          align: 'end',
          value: 'count'
        },
      ]
    }
  },
  methods: {
    async getSales (artistCode) {
      this.loading = true
      ReportApi.loadArtworkUnsoldProducts(artistCode).then((results) => {
        this.artworks = results.data
        this.loading = false
      })
    },
    downloadExcel () {
      const headers = [
          'artworkCode',
          'title',
          'value',
          'count'
      ]
      const workbook = xlsx.utils.book_new()
      const worksheet = xlsx.utils.json_to_sheet(this.artworks, { header: headers })
      xlsx.utils.book_append_sheet(workbook, worksheet, 'Artwork Performance')
      xlsx.writeFile(workbook, `${this.artist}-ARTWORK-PERFORMANCE.xlsx`)
    },
    async handleArtistChange (evt) {
      this.artist = evt.name
      await this.getSales(evt.artistCode)
    },
  },
  computed: {
  },
  async mounted () {
  }
}
</script>
