<template>
<v-container fluid>
  <component :is="activeComponent"></component>
</v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ReportApi from '@/api/admin/report'
import unsoldartworks from './unsold-artworks'
import unsoldproducts from './unsold-products'

export default {
  components: {
    unsoldartworks,
    unsoldproducts
  },
  data () {
    return {
      // activeComponent: null
    }
  },
  methods: {
  },
  computed: {
    ...mapGetters('reports', ['activeArtist']),
    activeComponent () {
      return this.$route.params.report
    }
  },
  async created () {
    // this.activeComponent = this.$route.params.report
    }
}
</script>
