<template>
  <v-select
    outlined
    dense
    v-model="artist"
    :items="artists"
    item-value="artistCode"
    label="Artist"
    item-text="name"
    return-object
    @change="handleArtistChange()"
  ></v-select>
</template>

<script>
import { mapGetters } from "vuex";
import ArtistApi from "@/api/admin/artist";

export default {
  props: {
    useActiveArtist: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      artists: [],
      artist: null,
      loadingData: true,
    };
  },
  methods: {
    handleArtistChange() {
      this.$emit("onChange", this.artist);
      if (this.useActiveArtist) {
        this.$store.dispatch("reports/setActiveArtist", this.artist.artistCode);
      }
    },
  },
  computed: {
    ...mapGetters({
      activeArtist: "reports/activeArtist",
    }),
  },
  async created() {
    this.artist = this.useActiveArtist ? this.activeArtist : null;
    ArtistApi.loadArtists()
      .then((results) => {
        this.artists = results.data;
        // this.artists.unshift({ name: "All", artistCode: null });
        this.loadingData = false;
      })
      .catch((err) => {
        console.log("err");
        this.loadingData = false;
      });
  },
};
</script>
